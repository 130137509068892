import React from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './index.css'
import 'react-toastify/dist/ReactToastify.css';

import routes from './routes';
import { AppProvider } from './contexts/AppContext';
// import { loadConfiguration } from './utils/configurationUtils';
// import LoadingOverlay from './components/admin/settings/LoadingOverlay';
// import LoadingSpinner from './components/common/LoadingSpinner';

const AppRoutes: React.FC = () => {
  const element = useRoutes(routes);
  return element;
};

const App: React.FC = () => {
  // const [isLoading, setIsLoading] = React.useState(true);

  // useEffect(() => {
  //   const initApp = async () => {
  //     const savedConfig = await loadConfiguration();
  //     setIsLoading(false);
  //     // Aquí puedes aplicar la configuración cargada
  //   };
  //   initApp();
  // }, []);

  // if (isLoading) {
  //   return <LoadingSpinner />;
  // }

  return (
    <AppProvider>
      <Router>
        <AppRoutes />
        <ToastContainer />
      </Router>
    </AppProvider>
  );
};

export default App;