// api.ts
import axios, {  InternalAxiosRequestConfig } from 'axios';
import { API_CONFIG } from '../config/api';
import { getToken } from './tokenService';

const api = axios.create({
  baseURL: API_CONFIG.BASE_URL,
  timeout: API_CONFIG.TIMEOUT,
  headers: API_CONFIG.HEADERS,
});

api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = getToken();
  if (token) {
    config.headers = config.headers || {};
    config.headers['Authorization'] = `Bearer ${token ?? ''}`;
  }
  return config;
});

export const get = (url: string, params = {}) => api.get(url, { params });
export const post = (url: string, data = {}) => api.post(url, data);
export const put = (url: string, data = {}) => api.put(url, data);
export const del = (url: string) => api.delete(url);
export const saveUserConfiguration = (url: string, data = {}) => api.post(url, data);
export const loadUserConfiguration = (url: string) => api.get(url);

export default api;