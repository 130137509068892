// utils/configurationUtils.ts

import { AppConfig } from '../../types/admin/ConfigType';
import api from '../../services/api';

export const saveConfiguration = async (config: AppConfig) => {
  try {
    const configToSave = {
      themeConfig: config.themeConfig,
      language: config.language,
      designConfig: config.designConfig,
      logoConfig: config.logoConfig,
      menuConfig: config.menuConfig,
    };

    // Enviar a la API
    //await api.post('/saveconfig', configToSave);

    // Guardar en localStorage
     localStorage.setItem('appConfig', JSON.stringify(configToSave));

    return true;
  } catch (error) {
    console.error('Error saving configuration:', error);
    return false;
  }
};

export const loadConfiguration = async (): Promise<AppConfig | null> => {
  try {
    const apiConfig = await api.get('/loadconfig');
    let config: AppConfig | null = null;

    if (apiConfig.data.status === 'success') {
      config = apiConfig.data.config;
    } else {
      const localConfig = localStorage.getItem('appConfig');
      if (localConfig) {
        config = JSON.parse(localConfig);
      }
    }

    if (config) {
      return {
        themeConfig: config.themeConfig || {},
        language: config.language || 'en',
        designConfig: config.designConfig || {},
        logoConfig: config.logoConfig || {},
        menuConfig: config.menuConfig || {},
      };
    }

    return null;
  } catch (error) {
    console.error('Error loading configuration:', error);
    return null;
  }
};

/**
 * 
 * @param hex valor en hexadecimal
 * @param brightness valor por defecto 119 
 * @returns 
 */
export const adjustBrightness = (hex:string, brightness:number) => {
  // Eliminar el símbolo # si está presente
  hex = hex.replace(/^#/, '');
  // Convertir a formato RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Ajustar el brillo
  r = Math.min(255, Math.max(0, r + brightness));
  g = Math.min(255, Math.max(0, g + brightness));
  b = Math.min(255, Math.max(0, b + brightness));

  // Convertir de vuelta a hexadecimal
  const newHex = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  return newHex;
}
