import React from 'react';
import { Box, Skeleton, CircularProgress, useTheme } from '@mui/material';

const LoadingSpinner: React.FC = () => {
  const theme = useTheme();

  return (
    <Box sx={{ 
      bgcolor: theme.palette.background.default, 
      minHeight: '100vh', 
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9999,
    }}>
      <Box sx={{ 
        height: 64, 
        bgcolor: theme.palette.background.paper, 
        boxShadow: theme.shadows[1],
        display: 'flex',
        alignItems: 'center',
        px: 2,
      }}>
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="text" sx={{ flexGrow: 1, ml: 2 }} width={200} />
        <Skeleton variant="circular" width={40} height={40} sx={{ ml: 1 }} />
        <Skeleton variant="circular" width={40} height={40} sx={{ ml: 1 }} />
        <Skeleton variant="circular" width={40} height={40} sx={{ ml: 1 }} />
      </Box>
      <Box sx={{ p: 3, width: '100%' }}>
        <Skeleton variant="rectangular" height={200} sx={{ mb: 2 }} />
        <Skeleton variant="text" sx={{ mb: 1 }} />
        <Skeleton variant="text" sx={{ mb: 1 }} />
        <Skeleton variant="text" width="60%" sx={{ mb: 3 }} />
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Skeleton variant="rectangular" width="32%" height={100} />
          <Skeleton variant="rectangular" width="32%" height={100} />
          <Skeleton variant="rectangular" width="32%" height={100} />
        </Box>
        
        <Skeleton variant="rectangular" height={400} sx={{ mb: 2 }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: theme.palette.mode === 'light' 
            ? 'rgba(255, 255, 255, 0.9)' 
            : 'rgba(0, 0, 0, 0.9)',
        }}
      >
        <CircularProgress size={60} thickness={4} />
        <Box
          component="h2"
          sx={{
            mt: 2,
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.h6.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
            margin: '16px 0 8px',
          }}
        >
          Cargando el sistema...
        </Box>
        <Box
          component="p"
          sx={{
            mt: 1,
            maxWidth: 300,
            color: theme.palette.text.secondary,
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body2.fontSize,
            margin: 0,
            lineHeight: 1.5,
          }}
        >
          Preparando su experiencia personalizada. Esto puede tomar unos momentos.
        </Box>
      </Box>
    </Box>
  );
};

export default LoadingSpinner;