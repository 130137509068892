import {
  ThemeType,
  PredefinedTheme,
  ThemeColors,
} from "../../types/admin/ThemeType";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const predefinedThemes: Record<ThemeType, PredefinedTheme> = {
  defaultGrey: {
    name: "Steel Grey",
    light: {
      primary: "#1976d2",
      secondary: "#dc004e",
      background: {
        default: "#f5f5f5",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#90caf9",
      secondary: "#f48fb1",
      background: {
        default: "#303030",
        paper: "#424242",
        line: "#656565",
      },
    },
    designConfig: {
      font: "Roboto",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "BA",
      color: "#ffffff",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: false },
      sideMenu: { color: "paper", contrast: false },
      selectedMenu: { color: "primary", contrast: false },
    },
  },
  yellowMustard: {
    name: "Yellow Mustard",
    light: {
      primary: "#f9a825",
      secondary: "#b1a525",
      background: {
        default: "#fffde7",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#ffd54f",
      secondary: "#cfdb76",
      background: {
        default: "#724b07",
        paper: "#946a04",
        line: "#a88734",
      },
    },
    designConfig: {
      font: "Montserrat",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "DY",
      color: "#fffde7",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: true },
      sideMenu: { color: "paper", contrast: true },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  sunset: {
    name: "Sunset",
    light: {
      primary: "#e65100",
      secondary: "#bb021e",
      background: {
        default: "#fff3e0",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#ffb74d",
      secondary: "#f73e5a",
      background: {
        default: "#6e1d06",
        paper: "#742a07",
        line: "#8f5336",
      },
    },
    designConfig: {
      font: "Arial",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "Sunset",
      color: "#fff3e0",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: false },
      sideMenu: { color: "paper", contrast: false },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  coffee: {
    name: "Coffee",
    light: {
      primary: "#4f3017",
      secondary: "#a8774d",
      background: {
        default: "#f6f3e9",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#937155",
      secondary: "#e4b389",
      background: {
        default: "#241401",
        paper: "#3c220c",
        line: "#614c3a",
      },
    },
    designConfig: {
      font: "Arial",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "Coffee",
      color: "#fff3e0",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: false },
      sideMenu: { color: "paper", contrast: false },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  chocolate: {
    name: "Chocolate",
    light: {
      primary: "#6d2f1c",
      secondary: "#be763c",
      background: {
        default: "#efe3d1",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#a96b58",
      secondary: "#fab278",
      background: {
        default: "#3e2723",
        paper: "#4e342e",
        line: "#705b55",
      },
    },
    designConfig: {
      font: "Montserrat",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "DS",
      color: "#7c5e55",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: false },
      sideMenu: { color: "paper", contrast: false },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  redApple: {
    name: "Red Apple",
    light: {
      primary: "#791515",
      secondary: "#9fa11b",
      background: {
        default: "#faf0f1",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#b55151",
      secondary: "#d2b80f",
      background: {
        default: "#4a0f0f",
        paper: "#691414",
        line: "#864040",
      },
    },
    designConfig: {
      font: "Roboto",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "RA",
      color: "#ffebee",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: false },
      sideMenu: { color: "paper", contrast: false },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  cherryBlossom: {
    name: "Cherry Blossom",
    light: {
      primary: "#f06292",
      secondary: "#4caf50",
      background: {
        default: "#fce4ec",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#f48fb1",
      secondary: "#81c784",
      background: {
        default: "#6c0f3a",
        paper: "#8c1d4b",
        line: "#a2486d",
      },
    },
    designConfig: {
      font: "Satisfy",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "SAK",
      color: "#fce4ec",
    },
    menuConfig: {
      topBar: { color: "paper", contrast: false },
      sideMenu: { color: "primary", contrast: false },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  royalPurple: {
    name: "Royal Purple",
    light: {
      primary: "#7b1fa2",
      secondary: "#c2185b",
      background: {
        default: "#f3e5f5",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#ba68c8",
      secondary: "#f06292",
      background: {
        default: "#270b4b",
        paper: "#471a5d",
        line: "#6a467c",
      },
    },
    designConfig: {
      font: "Open Sans",
      fontWeight: "normal",
      italic: true,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "PR",
      color: "#e1bee7",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: true },
      sideMenu: { color: "card", contrast: true },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  comodore: {
    name: "Comodore",
    light: {
      primary: "#867ade",
      secondary: "#6e4e83",
      colorText: "#3f3395",
      background: {
        default: "#dbd8fc",
        paper: "#c9c5f7",
        line: "#b1add9",
      },
    },
    dark: {
      primary: "#c4b8ff",
      secondary: "#aa8abf",
      colorText: "#c9c5f7",
      background: {
        default: "#867ade",
        paper: "#3f3395",
        line: "#645aa9",
      },
    },
    designConfig: {
      font: "Verdana",
      fontWeight: "bold",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "BS",
      color: "#867ade",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: false },
      sideMenu: { color: "paper", contrast: false },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  blueSky: {
    name: "Blue Sky",
    light: {
      primary: "#1565c0",
      secondary: "#39008f",
      background: {
        default: "#e0f7fa",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#1e88e5",
      secondary: "#753ccb",
      background: {
        default: "#003b54",
        paper: "#144b60",
        line: "#406d7e",
      },
    },
    designConfig: {
      font: "Arial",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "BS",
      color: "#e3f2fd",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: false },
      sideMenu: { color: "paper", contrast: false },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  midnight: {
    name: "Midnight",
    light: {
      primary: "#2e6d90",
      secondary: "#64b5f6",
      background: {
        default: "#e3f2fd",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#6dafd2",
      secondary: "#a6f6ff",
      background: {
        default: "#121921",
        paper: "#212a2f",
        line: "#4b5356",
      },
    },
    designConfig: {
      font: "Arial",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "BS",
      color: "#e3f2fd",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: false },
      sideMenu: { color: "paper", contrast: false },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  freshMint: {
    name: "Fresh Mint",
    light: {
      primary: "#00bfa5",
      secondary: "#5dee6e",
      background: {
        default: "#e0f2f1",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#1bdaaa",
      secondary: "#99ffaa",
      background: {
        default: "#19332f",
        paper: "#3a5350",
        line: "#5f7372",
      },
    },
    designConfig: {
      font: "Arial",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "MF",
      color: "#1e7672",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: false },
      sideMenu: { color: "paper", contrast: true },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  emerald: {
    name: "Emerald",
    light: {
      primary: "#004429",
      secondary: "#00796b",
      background: {
        default: "#e8f5e9",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#3d8267",
      secondary: "#5bd2af",
      background: {
        default: "#0d2a0e",
        paper: "#003a23",
        line: "#315f4d",
      },
    },
    designConfig: {
      font: "Lato",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "EG",
      color: "#1b5f42",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: true },
      sideMenu: { color: "paper", contrast: true },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  greenForest: {
    name: "Green Forest",
    light: {
      primary: "#8bc34a",
      secondary: "#ff9800",
      background: {
        default: "#f1f8e9",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#aed581",
      secondary: "#ffb74d",
      background: {
        default: "#1b3810",
        paper: "#344f22",
        line: "#5b714c",
      },
    },
    designConfig: {
      font: "Lato",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "LG",
      color: "#f1f8e9",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: false },
      sideMenu: { color: "paper", contrast: false },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  lime: {
    name: "Lime",
    light: {
      primary: "#c0ca33",
      secondary: "#569223",
      background: {
        default: "#f9fbe7",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#dce775",
      secondary: "#92ce5f",
      background: {
        default: "#2d3b01",
        paper: "#3e5101",
        line: "#637231",
      },
    },
    designConfig: {
      font: "Poppins",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "LM",
      color: "#f9fbe7",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: false },
      sideMenu: { color: "paper", contrast: false },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  monokai: {
    name: "Monokai",
    light: {
      primary: "#f92672",
      secondary: "#66d9ef",
      background: {
        default: "#f8f8f2",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#a6e22e",
      secondary: "#fd971f",
      background: {
        default: "#272822",
        paper: "#3e3d32",
        line: "#636259",
      },
    },
    designConfig: {
      font: "Courier New",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "MK",
      color: "#f8f8f2",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: true },
      sideMenu: { color: "paper", contrast: true },
      selectedMenu: { color: "secondary", contrast: false },
    },
  },
  custom: {
    name: "Custom",
    light: {
      primary: "#1976d2",
      secondary: "#dc004e",
      background: {
        default: "#f5f5f5",
        paper: "#ffffff",
        line: "#e0e0e0",
      },
    },
    dark: {
      primary: "#90caf9",
      secondary: "#f48fb1",
      background: {
        default: "#303030",
        paper: "#424242",
        line: "#656565",
      },
    },
    designConfig: {
      font: "Verdana",
      fontWeight: "normal",
      italic: false,
      shadows: false,
      expanded: false,
      design: "default",
    },
    logoConfig: {
      text: "GF",
      color: "#e8f5e9",
    },
    menuConfig: {
      topBar: { color: "primary", contrast: true },
      sideMenu: { color: "paper", contrast: false },
      selectedMenu: { color: "secondary", contrast: true },
    },
  },
};

export const getThemeColors = (
  theme: ThemeType,
  mode: "light" | "dark"
): ThemeColors => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return predefinedThemes[theme][mode];
};

// Definimos los estilos de diseño
export const designStyles = {
  default: {},
  compact: {
    typography: {
      fontSize: 14,
      h1: { fontSize: "2rem" },
      h2: { fontSize: "1.75rem" },
      h3: { fontSize: "1.5rem" },
      h4: { fontSize: "1.25rem" },
      h5: { fontSize: "1.1rem" },
      h6: { fontSize: "1rem" },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "0.875rem",
            padding: "5px 5px",
            margin: "5px 5px",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": {
              padding: "10px 14px",
            },
          },
        },
      },
    },
    spacing: (factor: number) => `${0.35 * factor}rem`,
  },
};
