import React, { createContext, useState, useEffect, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppConfig, ColorMode, ThemeType, ColorScheme, DesignType, LogoConfig, MenuConfig, DesignConfig, ThemeConfig } from '../types/admin/ConfigType';
import { loadConfiguration, saveConfiguration } from '../utils/admin/configurationUtils';
import { getThemeColors, designStyles, predefinedThemes } from '../config/admin/themeConfig';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../components/common/LoadingSpinner';

const defaultConfig: AppConfig = {
  themeConfig: {
    mode: 'system',
    theme: 'defaultGrey',
    lightColors: {
      colorText: '',
      primary: '#1976d2',
      secondary: '#dc004e',
      background: {
        default: '#f5f5f5',
        paper: '#ffffff',
        line: '#ff0000',
      },
      horizontalMenu: '#1976d2',
      verticalMenu: '#ffffff',
      logoBackground: '#1976d2',
    },
    darkColors: {
      colorText:'',
      primary: '#90caf9',
      secondary: '#f48fb1',
      background: {
        default: '#303030',
        paper: '#424242',
        line: '#1e1e1e',
      },
      horizontalMenu: '#1e1e1e',
      verticalMenu: '#424242',
      logoBackground: '#1e1e1e',
    },
  },
  designConfig: {
    font: 'Roboto',
    fontWeight: 'normal',
    italic: false,
    shadows: true,
    expanded: false,
    design: 'default',
  },
  logoConfig: {
    image: undefined,
    text: '',
    color: '#000000',
  },
  menuConfig: {
    topBar: { color: "primary", contrast: false },
    sideMenu: { color: "card", contrast: false },
    selectedMenu: { color: 'primary', contrast: false },
  },
  language: 'en',
};

export const AppContext = createContext<{
  config: AppConfig;
  updateConfig: (newConfig: Partial<AppConfig>) => void;
  setColorMode: (mode: ColorMode) => void;
  setTheme: (theme: ThemeType) => void;
  setLanguage: (lang: string) => void;
  updateDesignConfig: (newDesignConfig: Partial<DesignConfig>) => void;
  updateThemeConfig: (newThemeConfig: Partial<ThemeConfig>) => void;
  updateLogoConfig: (newLogoConfig: Partial<LogoConfig>) => void;
  updateMenuConfig: (newMenuConfig: Partial<MenuConfig>) => void;
  updateColorScheme: (mode: 'light' | 'dark', colors: Partial<ColorScheme>) => void;
  setDesign: (design: DesignType) => void;
  setFontFamily: (font: string) => void;
  setLogo: (logoUrl: string) => void;
  effectiveMode: 'light' | 'dark';
}>({
  config: defaultConfig,
  updateConfig: () => { },
  setColorMode: () => { },
  setTheme: () => { },
  setLanguage: () => { },
  updateDesignConfig: () => { },
  updateThemeConfig: () => { },
  updateLogoConfig: () => { },
  updateMenuConfig: () => { },
  updateColorScheme: () => { },
  setDesign: () => { },
  setFontFamily: () => { },
  setLogo: () => { },
  effectiveMode: 'light',
});

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [config, setConfig] = useState<AppConfig>(defaultConfig);
  const [isLoading, setIsLoading] = useState(true);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const { i18n } = useTranslation();

  useEffect(() => {
    const loadConfig = async () => {
      const loadedConfig = await loadConfiguration();
      setConfig(loadedConfig || defaultConfig);
      setIsLoading(false);
    };
    loadConfig();
  }, []);

  const updateConfig = (newConfig: Partial<AppConfig>) => {
    setConfig((prevConfig) => {
      if (!prevConfig) return prevConfig;
      const updatedConfig = { ...prevConfig, ...newConfig };
      saveConfiguration(updatedConfig);
      return updatedConfig;
    });
  };

  const setColorMode = (mode: ColorMode) => {
    updateConfig({ themeConfig: { ...config.themeConfig, mode } });
  };

  const setTheme = (theme: ThemeType) => {
    if (theme === 'custom') {
      updateConfig({ themeConfig: { ...config.themeConfig, theme } });
    } else {
      
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const selectedTheme = predefinedThemes[theme ?? 'defaultGrey'];
      updateConfig({
        themeConfig: {
          ...config.themeConfig,
          theme,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          lightColors: selectedTheme.light,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          darkColors: selectedTheme.dark,
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        designConfig: {
          ...config.designConfig,
          ...selectedTheme.designConfig,
        },
        logoConfig: {
          ...config.logoConfig,
          ...selectedTheme.logoConfig,
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        menuConfig: {
          ...config.menuConfig,
          ...selectedTheme.menuConfig,
        },
      });
      /* eslint-enable @typescript-eslint/no-explicit-any */
    }
  };

  const setLanguage = (language: string) => {
    updateConfig({ language });
    i18n.changeLanguage(language)
  };

  const updateColorScheme = (mode: 'light' | 'dark', colors: Partial<ColorScheme>) => {
    updateConfig({
      themeConfig: {
        ...config.themeConfig,
        [`${mode}Colors`]: {
          ...config.themeConfig?.[`${mode}Colors`],
          ...colors,
          background: {
            ...config.themeConfig?.[`${mode}Colors`]?.background,
            ...(colors.background || {}),
          },
        },
        theme: 'custom',
      },
    });
  };

  const updateThemeConfig = (newThemeConfig: Partial<ThemeConfig>) => {
    updateConfig({
      themeConfig: { ...config.themeConfig, ...newThemeConfig },
    });
  };

  const updateDesignConfig = (newDesignConfig: Partial<DesignConfig>) => {
    updateConfig({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      designConfig: { ...config.designConfig, ...newDesignConfig },
    });
  };

  const updateLogoConfig = (newLogoConfig: Partial<LogoConfig>) => {
    updateConfig({
      logoConfig: { ...config.logoConfig, ...newLogoConfig },
    });
  };

  const updateMenuConfig = (newMenuConfig: Partial<MenuConfig>) => {
    updateConfig({
      menuConfig: { ...config.menuConfig, ...newMenuConfig },
    });
  };
  const setDesign = (design: DesignType) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    updateConfig({ designConfig: { ...config.designConfig, design } });
  };

  const setFontFamily = (font: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    updateConfig({ designConfig: { ...config.designConfig, font } });
  };

  const setLogo = (logoUrl: string) => {
    updateConfig({ logoConfig: { ...config.logoConfig, image: logoUrl } });
  };

  const effectiveMode = useMemo(() => {
    if (!config) return prefersDarkMode ? 'dark' : 'light';
    switch (config.themeConfig?.mode) {
      case 'light': return 'light';
      case 'dark': return 'dark';
      default: return prefersDarkMode ? 'dark' : 'light';
    }
  }, [config, prefersDarkMode]);

  const muiTheme = useMemo(() => {
    if (!config) return createTheme();

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const themeColors = config.themeConfig?.theme === "custom" ? config.themeConfig?.[`${effectiveMode}Colors`] : getThemeColors(config.themeConfig?.theme   , effectiveMode)??'';
    /* eslint-enable @typescript-eslint/no-explicit-any */

    const designStyle = designStyles[config.designConfig?.design || "default"];

    const getSpacing = (spacingFactor: number) => {
      const baseSpacing = config.designConfig?.expanded ? 4 : 6;
      return `${baseSpacing * spacingFactor}px`;
    };

    const topBarColor = (config.menuConfig?.topBar?.color ?? "primary") === 'primary'
      ? themeColors?.background?.paper
      : themeColors?.background?.paper;

    const sideBarColor = config?.menuConfig?.sideMenu?.color ?? "primary" === 'primary'
      ? themeColors?.primary
      : themeColors?.background?.paper;
     return createTheme({
      palette: {
        //  text: {
        //  primary: '#333333', // Color principal para la mayoría de los textos
        //  secondary: '#666666', // Color para textos secundarios
        // disabled: '#999999', // Color para textos deshabilitados
        //  },
        mode: effectiveMode,
        primary: { main: themeColors?.primary ?? '' },
        secondary: { main: themeColors?.secondary ?? '' },
        background: {
          default: themeColors?.background.default,
          paper: themeColors?.background.paper,
        },
      },
       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
       // @ts-ignore
      typography: {
        fontFamily: config.designConfig?.font,
        // fontWeightRegular: config.designConfig?.fontWeight === "bold" ? 600 : 400,
      },
      
      spacing: getSpacing,
      components: {
        MuiAppBar: {
          styleOverrides: {
            root: {
               backgroundColor: topBarColor,
              // color: config.menuConfig.topBar.contrast ? '#ffffff' : 'inherit',
            },

          },
        },
        MuiDrawer: {
          styleOverrides: {
            paper: {
              backgroundColor: sideBarColor,
              // color: config.menuConfig.sideMenu.contrast ? '#ffffff' : 'inherit',
            },
            
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
              fontStyle: config.designConfig?.italic ? "italic" : "normal",
              fontWeight: config.designConfig?.fontWeight === "bold" ? 600 : 400,
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
              fontStyle: config.designConfig?.italic ? "italic" : "normal",
              fontWeight: config.designConfig?.fontWeight === "bold" ? 600 : 400,
            },
          },
        },
        MuiContainer: {
          styleOverrides: {
            root: {
              padding: getSpacing(2),
              '& .MuiPaper-root': {
                // backgroundColor: sideBarColor,
                // color: config.menuConfig.sideMenu.contrast ? '#ffffff' : 'inherit',
                border: config.designConfig?.shadows ? 'none' : `1px solid ${themeColors?.background.line}`,
                // Otros estilos para Paper dentro de Container
              },
              '& .MuiCard-root': {
                // backgroundColor: sideBarColor,
                // color: config.menuConfig.sideMenu.contrast ? '#ffffff' : 'inherit',
                border: config.designConfig?.shadows ? 'none' : `1px solid ${themeColors?.background.line}`,
                // Otros estilos para Card dentro de Container
              },
            },
          },
        },
        
        MuiPaper: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
              boxShadow: config.designConfig?.shadows ? undefined : "none",
              fontStyle: config.designConfig?.italic ? "italic" : "normal",
              // fontWeight: config.designConfig?.fontWeight === "bold" ? 600 : 400,
              // border: config.designConfig?.shadows ? 'none' : `1px solid ${themeColors?.background.line}`,
            },
            // legend:{
            //   fontWeight: config.designConfig?.fontWeight === "bold" ? 600 : 400,

            // }
          },
        },
        
        MuiCard: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
              boxShadow: config.designConfig?.shadows ? undefined : "none",
              border: config.designConfig?.shadows ? 'none' : themeColors?.background.line,
              // margin: getSpacing(1),
            },
          },
        },
        MuiGrid: {
          styleOverrides: {
            container: {
              // margin: `-${getSpacing(1)}`,
            },
            item: {
              padding: getSpacing(1),
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              // color: themeColors?.colorText ?? 'inherit',
              // margin: getSpacing(0.5),
              padding: `${getSpacing(0.75)} ${getSpacing(1.5)}`,
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              // color: themeColors?.colorText ?? 'inherit',
              // margin: getSpacing(1),
            },
          },
        },
        MuiFormControl: {
          styleOverrides: {
            root: {
              fontWeight: 400,
              // color: themeColors?.colorText ?? 'inherit',
              // margin: getSpacing(1),
              '& legend': {
                color: themeColors?.colorText ?? 'inherit',
                fontWeight: config.designConfig?.fontWeight === "bold" ? 600 : 400,
              },
            }
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
              padding: getSpacing(1),
            },
          },
        },
        MuiListItem: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
              paddingTop: getSpacing(0.5),
              paddingBottom: getSpacing(0.5),
            },
          },
        },
 
        MuiInputBase: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
            },
            input: {
              color: themeColors?.colorText ?? 'inherit',
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit', '& .MuiOutlinedInput-notchedOutline': { borderColor: themeColors?.colorText ?? 'rgba(0, 0, 0, 0.23)', },
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            paper: {
              border: config.designConfig?.shadows ? 'none' : `1px solid ${themeColors?.background.line}`,
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
             // border: '1px solid red'
             // color: themeColors?.colorText ?? 'inherit',
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
            },
          },
        },
        MuiRadio: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
            },
          },
        },
        // MuiSwitch: {
        //   styleOverrides: {
        //     root: {
        //       '& .MuiSwitch-thumb': {
        //         color: themeColors?.colorText ?? 'inherit',
        //       },
        //     },
        //   },
        // },
       
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
            },
          },
        },
        MuiListItemText: {
          styleOverrides: {
            primary: {
              color:   'inherit',
            },
            secondary: {
              color: themeColors?.colorText ?? 'inherit',
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              color: themeColors?.colorText ?? '#fff',
              backgroundColor: themeColors?.colorText ?? 'rgba(97, 97, 97, 0.92)',
            },
          },
        },
        MuiAlert: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
            },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
            },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
            },
          },
        },
        // MuiLegend:{
        //   styleOverrides: {
        //     root: {
        //       fontWeight: config.designConfig?.fontWeight === "bold" ? 600 : 400,
        //     },
        //   },
        // },
        MuiDialogContentText: {
          styleOverrides: {
            root: {
              color: themeColors?.colorText ?? 'inherit',
            },
          },
        },
        


      },
      ...designStyle,
    });
  }, [config, effectiveMode]);
  if (isLoading) {
    return <LoadingSpinner />;  // Asegúrate de importar o crear este componente
  }
  return (
    <AppContext.Provider
      value={{
        config,
        updateConfig,
        setColorMode,
        setTheme,
        setLanguage,
        updateDesignConfig,
        updateThemeConfig,
        updateColorScheme,
        updateLogoConfig,
        updateMenuConfig,
        setDesign,
        setFontFamily,
        setLogo,
        effectiveMode,
      }}
    >
      <ThemeProvider theme={muiTheme}>
        {children}
      </ThemeProvider>
    </AppContext.Provider>
  );
};