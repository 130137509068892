import { post } from './api';
import { setToken, removeToken, getToken } from './tokenService';

export const login = async (email: string, password: string) => {
  const response = await post('/admin/login', { ci:email, password });
  const { token, user } = response.data.data;
  setToken(token);
  localStorage.setItem('user', JSON.stringify(user));
  return user;
};

export const logout = () => {
  localStorage.removeItem('user');
  removeToken();
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  return null;
};

export const isAuthenticated = () => {
  return !!getToken();
};