import React, { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import ProtectedRoute from '../components/ProtectedRoute';
import LoadingSpinner from '../components/common/LoadingSpinner';
import NotFoundPage from '../pages/NotFoundPage';
import UnauthorizedPage from '../pages/UnauthorizedPage';

// Lazy loading para mejorar el rendimiento
const AdminLoginPage = lazy(() => import('../pages/admin/LoginPage'));
const AdminDashboardPage = lazy(() => import('../pages/admin/DashboardPage'));
const ConfigurationPage = lazy(() => import('../pages/admin/ConfigurationPage'));
const AdminLandingPage = lazy(() => import('../pages/admin/AdminLandingPage'));


// import withLoadingDelay from '../components/common/WithLoadinDelay';

// const DelayedConfigurationPage2 = withLoadingDelay(ConfigurationPage2);

// withLoadingDelay(ConfigurationPage2)
const routes: RouteObject[] = [
  {
    path: ':company',
   
    children: [
      {
        path: 'login',
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <AdminLoginPage />
          </Suspense>
        ),
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
             path: '',
             element: (
              <Suspense fallback={<LoadingSpinner />}>
                <AdminLandingPage />
              </Suspense>
            ),
          },
          {
            path: 'dashboard',
            element: (
              <Suspense fallback={<LoadingSpinner />}>
                <AdminDashboardPage />
              </Suspense>
            ),
          },
          {
            path: 'settings',
            element: (
              <Suspense fallback={<LoadingSpinner />}>
                <ConfigurationPage />
              </Suspense>
            ),
          },
          {
            path: 'users/roles/user',
            element: (
              <Suspense fallback={<LoadingSpinner />}>
                <ConfigurationPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/unauthorized',
    element: <UnauthorizedPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export default routes;